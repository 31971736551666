import React from "react";
import "./Oferta.css";
import Heading from "../components/Heading";
import Section from "../components/Section";
import { useTranslation } from "react-i18next";

export default function Oferta() {
  const { t } = useTranslation();

  return (
    <div className="oferta">
      <Heading
        text={t("oferta_heading").toUpperCase()}
        img={
          "https://firebasestorage.googleapis.com/v0/b/bestpol-64906.appspot.com/o/assets%2FAquisPlazaHaupteingang_s3169.jpg?alt=media&token=1bd3db5b-87f6-4b0a-8aab-2264098ca4fe"
        }
      />
      <div className="oferta_sections">
        {/* <section>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/bestpol-64906.appspot.com/o/assets%2Foferta_2_image.jpg?alt=media&token=fb548425-8c48-4c18-94c9-cd3e31c7c3f2"
            alt="obraz poglądowy"
          />

          <div className="oferta_section_content">
            <div className="oferta_section_content_container">
              <div className="oferta_section_content_top">
                <p>{t("oferta_section_heading1")}</p>
              </div>

              <div className="oferta_section_content_bottom">
                <p>{t("oferta_section_content1")}</p>
              </div>
            </div>
          </div>
        </section> */}

        <section>
          <div className="oferta_section_content">
            <div className="oferta_section_content_container">
              <div className="oferta_section_content_top">
                <p>{t("oferta_section_heading2")}</p>
              </div>

              <div className="oferta_section_content_bottom">
                <p>{t("oferta_section_content2")}</p>
              </div>
            </div>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2FWhatsApp%20Image%202022-09-28%20at%2010.15.04.jpeg?alt=media&token=49c8073c-2c5e-4933-8f28-0faf6d7ab37f"
            alt="obraz poglądowy"
          />
        </section>
      </div>
    </div>
  );
}
